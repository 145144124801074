/*------------------------------------------------------------
4.5 text
-----------------------------------------------------------*/

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-prewrap   { white-space: pre-wrap !important; }
//.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.text-unset { text-transform: none !important; }
.text-upper-first::first-letter {
	text-transform: uppercase !important;
}
.text-upper-first{
	text-transform: none !important;
}

.font-bold{
  font-weight: $font-weight-bold;
}
.font-bold2{
	font-weight: bold !important;
  }
.font-italic{
  font-style:$font-weight-italic;
}
.font-normal{
  font-weight: $font-weight-base;
}
.font-medium{
  font-weight: $font-weight-medium;
}
.font-light{
  font-weight: 300;
}
.text-14{
  font-size:0.875rem;
}
.text-14-important{
	font-size:0.875rem !important;
  }
.text-16{
	font-size:1rem;
}
.text-18{
	font-size:1.125rem;
}
.text-28{
	font-size:1.75rem;
}
.text-padding-left{
	padding: 30px;
  }
.text-underline{
  text-decoration: underline;
}
.lineheight-2{
	line-height: 2;
}