/*------------------------------------------------------------
    4.4 color
-------------------------------------------------------------*/


.primary-color{
  color:$primary;
}
.primary-color-important{
	color:$primary !important;
  }
.secondary-color{
  color:$secondary;
}
.success-color{
  color:$success;
}
.danger-color{
  color:$danger;
}
.warning-color{
  color:$warning;
}
.info-color{
  color:$info;
}
.dark-color{
  color:$dark;
}
.active-color{
  color:$active;
}
.active-color-important{
	color:$active !important;
  }
.base-color{
  color: $base;
}

