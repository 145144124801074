/*------------------------------------------------------------
    3.3 common
-----------------------------------------------------------*/
.h-100{
    height:100%;
}
.w-100{
    width:100%;
}

.selector-link{
    font-size:14px !important;
    text-transform: capitalize !important;
}
.iron-shadow{
    box-shadow: $box-shadow !important;
}

.hover-box-shadow{
    transition: box-shadow .3s ease-in-out;
    &:hover{
        box-shadow: 0 0 16px 0 rgba($black,.12),0 16px 16px 0 rgba($black,.24)  !important; 
    }
    
}
.border-circle{
   border-radius:100%;
}
.rounded{
    border-radius:0.3125rem;
    img{
      border-radius:0.3125rem;
    }
}

.post-rounded{
    border-radius:0.3125rem;
    img{
      border-top-left-radius:0.3125rem;
      border-top-right-radius:0.3125rem;
    }
}

.overflow-hidden{
	overflow: hidden;
}

.section-pad{
    padding: 5rem 0;
}

.text-line-through{
	text-decoration: line-through;
}

.text-truncate{
    text-overflow: ellipsis;
    max-width:100%;//8rem
    overflow: hidden;
    word-break: break-word;
    word-wrap: break-word;
    white-space: nowrap;
}

//-------rct card style ---------
.rct-card-wrap{
    border-radius:0.3125rem;
    box-shadow: 0 3px 1px -2px rgba($black,.2),
    0 2px 2px 0 rgba($black,.14),
    0 1px 0.3125rem 0 rgba($black,.12);
    padding: 1.5625rem;
    background-color: $base;
    margin-bottom: 1.875rem;
}

//-------listing style --------
.no-style{
    li{
        display: block;
        margin-bottom:0.3125rem;
    }
}

//------bullet list style-------
.bullets-list{
    li{
        display: block;
        position: relative;
        font-size:0.875rem;
        font-weight:$font-weight-base;
        padding-left: 1rem;
        margin-bottom: 0.3rem;
        &:before{
            content: '';
            width: 5px;
            height: 5px;
            display: inline-block;
            background: $black;
            border-radius: 100%;
            position: absolute;
            top: 46%;
            left: 2px;
        }
        
    }
}

//------ meta tag style ------
.iron-meta-info{
    .meta-list{
        display:inline-block;
        margin-right:12px;
        a{
            color: $secondary;		
            font-size: 0.75rem;	
            text-transform: capitalize;
            i{
                font-size: 0.875rem;
                vertical-align: middle;
            }
        }
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            a{
                color:$dark;
            }
        }
    }
}

//------- social icons style ----------
.iron-social-icons{
    li{
        display:inline-block;
        margin-right:0.3125rem;
        button,a{
            width: 2.5rem;
            height: 2.5rem;
            background-color:$primary;
            border:2px solid $primary;
            color:$base;
            transition: all 0.4s ease-in-out 0s;
            i{
                font-size:1.5rem;
            }
        }
        &:hover{
            button,a{
                background-color: $base;
                color:$primary;
            }
        }
    }
}

//-------blog content overlay color --------
.primary-rgba {
    background: rgba($primary,.75);
    //border-radius: 10px;
}
.active-rgba {
    background: rgba($active,.75);
    //border-radius: 10px;
}
.black-rgba {
    background: rgba($black,.69);
}
.white-rgba {
    background: rgba($white,.69);
}
.pink-rgba {
    background: rgba(183,58,174,.75);
}

//-------- rating star style---------
.rating-star{
    li{
        display: inline-block;
        i{
            color: $rating-default;		
            font-size: 1.4rem;		
            line-height: 1.25rem;
        }   
    }
    .active{
        i{
            color:$rating;
        }
    }
}

//------ product slider tab bar -------
.iron-tab-bar{
    .iron-tab-btn{
        >span:first-child{
            opacity: 0.7;
            font-weight: 700;
            z-index: 5;
        }
        >span:nth-child(2){
            display:none;
            color:$secondary;
            
        }   
    }
    .active{
        background: rgba($base, 0.3) !important;
    }
}

//payment tab scroll hide
.iron-tab-bar{
    .button-scroll-hide{
        >div:nth-child(2){
            >div:nth-child(1),>div:nth-child(3){
                display:none;
            }
        }
    }
}

//------- alertbox style---------
.sweet-alert {
    .btn-warning{
        border:none;
        color:$base;
        background-color:$active !important;
        font-weight:700;
        border-radius: 4px;
        font-size:1rem;
        box-shadow: 0 3px 1px -2px rgba($black,.2),
        0 2px 2px 0 rgba($black,.14),
            0 1px 5px 0 rgba($black,.12);
        cursor:pointer;
        line-height: 3;
    }
}
//------- blog grid Item style --------
.iron-post-item{
    .iron-overlay-wrap{
        position: relative;
        .iron-overlay-content{
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            pointer-events: none;
            .iron-overlay-holder{
                pointer-events: visible;
            }    
        }
    }
    .iron-btn-grp{
        position: absolute;
        top:-30px;
        right:20px;
        z-index:9;
        transition: transform 0.4s ease-out;
        .btn-wrap{
            height: 3.5rem;
            width: 3.5rem;
            padding:0;
            background-color: $active;
            box-shadow:0 3px 5px -1px rgba($black,.2),
             0 6px 10px 0 rgba($black,.14),
              0 1px 18px 0 rgba($black,.12);
            i{
                color:$base;
            }
        } 
        &:hover{
            .btn-wrap{
                background-color: $active;
                //i{
                //}
            }  
        }
    }
    &:hover{
        .iron-btn-grp{
            transform: translateY(-30px);
        }
    }
}

//progress bar
.iron-progress-bar{
   height:100vh;
   background-color:$base;
   position: fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   z-index: 99998;
   svg{
	  z-index: 99999;
	  color:$primary;
   }
}
//fragment progress bar
.fragment-progress-bar{
	display: flex;
	z-index: 99998;
	svg{
	   z-index: 99999;
	   color:$primary;
	}
 }

//transparent progress bar
.iron-transp-progress-bar{
	height:100vh;
	background-color:rgba($base,.50);
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: 99998;
	svg{
	   z-index: 99999;
	   color:$primary;
	}
 }

.app-container{
   position: relative;
   .setting-box{
      position: fixed;
      top: 40%;
      right: 0;
   }
}
//admin-panel
.app-card{
   position: relative;
   border-radius:0.3125rem;
   background-color: $base;
   box-shadow: 0 1px 1px 0 rgba($black, 0.14),
    0 2px 1px -1px rgba($black, 0.12),
    0 1px 3px 0 rgba($black, 0.2) !important;
   margin-bottom:1.875rem; 
   .app-card-title{
      padding: 1.25rem;
   }
   .app-content{
      padding:1.25rem;
   }
   .app-footer{
      padding: 1.25rem;
   }
   .btn-wraps{
      button{
         height: 40px;
         width: 40px;
         padding: 0;
         box-shadow: none;
         min-width: auto;
         border-radius: 100%;
      }
   }
}
//section -loader
.loader-overlay {
	position: absolute;
	background: rgba($base, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0px;
	z-index: 97;
	align-items: center;
	overflow: hidden;
  margin: 0 auto;
}

//payment tabs
.border {
	border: 1px solid $border-color;
}

.border-x {
	border-right: 1px solid $border-color;
	border-left: 1px solid $border-color;
}
.border-y {
	border-top: 1px solid $border-color;
	border-top: 1px solid $border-color;
}
.border-b {
	border-bottom: 1px solid $border-color;
}
.border-l {
	border-left: 1px solid $border-color;
}
.border-r {
	border-right: 1px solid $border-color;
}
.border-t {
	border-top: 1px solid $border-color;
}
.payment-int-wrap {
	border: none;
}
.payment-int-wrap {
	border: none;
}
.payment-int-tab {
	flex-flow: 1;
	border: 1px solid $border-color;
	display: flex;
	background: $white;
	.payment-sidebar {
		width: 200px;
		background: $gray-shade-2;
		.payment-logo {
			height: 80px;

			border: 1px solid $border-color;
			background-repeat: no-repeat;
			&.Mui-selected {
				background-color: $white;
			}
		}
	}
}

@media (max-width: 559px) {
	.btn-sec-wrap {
		text-align: left;
		margin-bottom: 1rem;
	}
	.sidebar-wrap-cong {
		width: 280px !important;
		.stock-chart {
			.chart-wrap {
				display: none;
			}
		}
	}
	.customizer-wrap > div {
		width: 280px !important;
	}
	.payment-int-tab {
		display: block;
		.payment-sidebar {
			width: 100%;
			.payment-logo {
				width: 150px;
			}
		}
		.MuiTabs-flexContainerVertical {
			flex-direction: row;
		}
	}
	.sig-digital {
		width: 200px !important;
	}
}

.page-space {
	padding-bottom: 5rem;
	padding-top: 5rem;
}

.page-space-top {
	padding-top: 5rem;
}

.page-mp-auth {
	background: $primary;
	//height: 100vh;
	overflow: auto;
	padding-top: 3rem;
	padding-bottom: 3rem;
	.page-space-top {
		padding-top: 0;
	}
}

.page-mp-auth-content {
	margin-top: 1rem;

	h5 {
		color: $white;
		margin-bottom: 1.3rem;
	}

	p {
		color: $white;
		margin-bottom: 1rem;
	}
}

.page-mp-auth-img {
    height: 256px;
    width: 256px;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.action-btn {
    margin-right: 3px !important;
}

.cookie-notification--title {
    flex: 1;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    align-self: center;
}

.cookie-notification--btns {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: space-between;
}

.login .cookie-notification--btn-privacy,
.cookie-notification--btn-privacy {
    //padding: 8px 24px;
    padding-right: 40px;
    align-self: center;
    //padding-bottom: 8px;
    border-radius: 4px;
    font-size: 13px;
    color: #fff;
}

.cookie-notification--btn-acknowledge {
    padding: 8px 50px;
    border-radius: 4px;
    font-size: 13px;
    margin-left: 4px;
    background: #fff;
    color: #1E90FF;
    cursor: pointer;
}

.cookie-notification--copy {
    font-size: 13px;
    min-width: 100%;
    color: #fff;
    text-align: justify;
    /* unvisited link */
    a:link {
        color: rgba($active, 0.8);
    }
  
    /* visited link */
    a:visited {
        color: rgba(hotpink, 0.8);
    }
    
    /* mouse over link */
    a:hover {
        color: $active;
    }
    
    /* selected link */
    a:active {
        color: $active;
    }
}

.cookie-notification--box {
    display: flex;
    flex-wrap: wrap;
    //padding: 24px 36px;
    justify-content: space-between;
    align-items: baseline;
}