/*------------------------------------------------------------
  5.1.1 headers
-----------------------------------------------------------*/

//header one styling
.iron-header-v1{
  box-shadow: none !important;
  background-color:$primary;
  .iron-header-top{
    background-color: rgba($primary,.8); 
  }
  .iron-header-bottom{
   min-height:64px;
   background-color:$primary;
  }
  .iron-header-menu{
    li{
      a{
        color: $base;
      }
    }
  }
}

