/*------------------------------------------------------------
    3.5 form
-----------------------------------------------------------*/

//-------basic form fields style--------
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    &:focus{
        outline: none;
    }
}
input{
    &::placeholder{
        text-transform: capitalize;
    }
    &::-ms-clear {
        width : 0;
        height: 0;
    }
}

.iron-form-input-wrap{
    > div{
        &:after{
            border-color:rgba($primary,0.8) ;
        }
    }
    > div:hover{
        &:before{
            border-color:rgba($primary,0.8) !important;
        }
    }       
}

.iron-form-input-wrap{
    width: 100%;
    margin-bottom: 0.75rem !important;
    label {
        //text-transform: capitalize;
        font-size: 0.875rem  !important;
    }
}

.iron-select-width1,.iron-select-width,.iron-select-width2{
    text-transform: capitalize;
}
.iron-select-width1{
    width:80px
}
.iron-select-width{
    width:100%;   
}
.iron-select-width2{
    width: 6.875rem;
} 
.product-values{
    .iron-select-width2{
        padding-right:20px;
        width: calc(100% / 3 - 20px);
        margin-bottom: 1.25rem;
        &:last-child{
            padding-right: 0;
        }
    }
}
 

//-------payment section coupon form style-------
.iron-coupon-form{
    width:15.625rem;
    margin:0 auto;
    .button{
        padding: 0 5px !important;
        min-width: auto;
        background-color: $transparent !important;
        box-shadow: none;
    }
    label{
        text-transform: capitalize;
        font-size:0.875rem;
    }
}

//-------shop page widgets style----------
.iron-shop-wrapper{
    .ais-Panel-header {
        border: none;
    }
    .ais-RefinementList-list,
    .ais-NumericMenu-list{
        li{
            label{
                margin: 0;
                padding: 0.5rem;
                display: flex;
               	display: -ms-flexbox;
               	align-items: center;
               	-ms-flex-align: center;
                cursor: pointer;
                input{
                    margin-right:0.625rem;
                    height: 1rem;
                    width: 1rem;
                    cursor: pointer
                }
                .ais-RefinementList-labelText{
                   padding-right:0.5rem;
                   font-size: 0.875rem;
                }
            }
        }
    }
    .ais-ClearRefinements-button {
        font-size: 0.875rem;
        padding: .5rem 1rem;
        border-radius: 3px;
        color: $base;
        font-weight: 700;
    }
    .ais-MenuSelect-select, 
    .ais-SortBy-select {
        width: 100%;
        background-image: none;
        cursor: pointer;
        padding:0.5rem 1.5625rem 0.5rem 0.9375rem;
    }
    .iron-filters-wrapper{
        .ais-RangeInput-input{
            width: 4rem;
        }
    }
    .app-selectbox, .app-selectbox-sm {
        border: 1px solid none;
        width: 30%;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
        &:before{
            content: "";
            position: absolute;
            pointer-events: none;
            top: 50%;
            margin-top: -0.09em;
            right: 15px;
            width: 0;
            height: 0;
            border: 0.3em solid $transparent;
            border-top-color: #464D69;
        }
    }
    .ie10 .app-selectbox, .ie10 .app-selectbox-sm {
        &:before{
            display:none;
        }
    }
    .ais-Hits-list .ais-Hits-item {
        border: none;
        box-shadow: none;
        margin: 0 0 1.875rem 0;
        padding: 0 0.9375rem;
        display: grid;
    }
    .ais-Hits-item{
        .iron-product-item {
            .iron-overlay-wrap{
                .iron-overlay-content{
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }
                .iron-overlay-holder{
                    padding:0.625rem;
                    button{
                        i{
                            color:rgba($black,0.4);
                        }
                        &:hover{
                            background-color: $transparent;
                        }
                        span{
                            &:last-child{
                                display:none;
                            }
                        }
                    }
                    button.active{
                        i{
                            color:$black;
                        }
                    }
                }
            }
        }    
    }
}

//---------filter style------------

/* Stats */
.ais-Stats-text{
    color:$dark;
    font-size:0.875rem;
    font-weight:700;
}

/* Panel */
.ais-Panel-header{
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
    color:$dark;
}

/* Search Box */
.ais-SearchBox-form {
    input {
        border-radius:0;
        border:0;
        border-bottom: 1px solid #d5d5d5;
        padding: 0.5rem 0;
        font-size: 1rem;
        &::placeholder{
            color: rgba($dark,0.5);
        }
    }    
    .ais-SearchBox-submitIcon{
        display: none;
    }
}
/* Hierachical Menu */
.ais-HierarchicalMenu-link{
	
	color:$dark;
    // font-weight:400;
	// padding-right:0.5rem;
	//font-size: 0.2rem !important;
	// //font:inherit;
	 margin: 0;
	// //padding: 0.2rem;
	 display: flex;		//
	// justify-self: start;
	// display: -ms-flexbox;
	 align-items: center;
	// -ms-flex-align: center;
	 padding-bottom: 0.5rem;
	//font-family: inherit !important;
	//cursor: pointer;
	//display: block;
  	line-height: 1.5;
	&:hover{
		color:$dark;
		font-weight: bold;
	}
	&:focus{
		color:$dark;
	}	
}

.ais-HierarchicalMenu-label {
 	font-size: 0.85rem;
}

// .ais-HierarchicalMenu-item{
//   -moz-user-select: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

.ais-HierarchicalMenu-link--selected{
 	color:$dark;
 	font-weight: bold;
 }

// .ais-HierarchicalMenu-list {
// 	font-weight: 1px !important;
// 	line-height: 1 !important;
	
// }

  .ais-HierarchicalMenu-link:before {
  margin-left: 0rem;
  margin-right: 0.5rem;
  content: '';
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
    
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M7.3 24l-2.8-2.8 9.3-9.2-9.3-9.2 2.8-2.8 12.2 12z%27 fill%3D%22%233A4570%22 /%3E%3C/svg%3E");
  background-size: 100% 100%; }
  
.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
	transform: rotate(90deg);
	//-webkit-transform: rotate(90deg);
}

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link:after {
	transform: rotate(90deg); 
	display: none;
}
  
.ais-HierarchicalMenu-item--selected
	.ais-HierarchicalMenu-item:not(.ais-HierarchicalMenu-item--selected)
	.ais-HierarchicalMenu-link::before {
	transform: rotate(0);
	
}

/* Refinamente List */
.ais-RefinementList-labelText{
    color:$dark;
    font-weight:500;
}

/* Menu */
.ais-Menu-link{
	color:$dark;
	//font-weight:bold;
	&:hover{//,
		color:$dark;
		font-weight: bold;
	}
	&:focus{
		color:$dark;
	}	
}
.ais-Menu-item--selected {
	color:$dark;
	font-weight: bold; 
}

.ais-Menu-label{
	font-size: 0.875rem;
	//font-weight: 700;
}
/* Numeric Menu */
.ais-NumericMenu-labelText{
    color:$dark;
    font-weight:500;
}

/* Range Input */
.ais-RangeInput-submit{
    color: $base;
    font-weight:500;
    padding:0 0.9375rem;
    background-color: $active;
    border-radius: 3px;
    font-size: .8rem;
    height:2rem;
    margin-left:0.9375rem;
    &:hover{
        background-color: $primary; 
    }
}
.ais-RangeInput-input{
    border:0;
    height:2rem;
    border-bottom: 1px solid $secondary;
    &:focus{
        outline: 0;
    }
}
input{
    &::placeholder{
        color: rgba($dark,0.5) !important;
    }
}
.ais-RangeInput-separator {
    margin: 0 0.8rem;
    font-size: 1.25rem;
}
/* Rating Menu */
.ais-RatingMenu-list{ 
    .ais-RatingMenu-item{
        margin-bottom:1rem;
    }
    .ais-RatingMenu-starIcon {
        fill: $active;
        width:20px;
		height:20px;
		
	}
	.ais-RatingMenu-starIcon--empty {
		fill: rgba($active, 0.6);
	  }
    .ais-RatingMenu-count{
        color: $base;
        font-weight:500;
        padding:0.1rem 0.4rem;
        background: rgba($black,.4);
        border-radius: 3px;
        font-size: .8rem;
        &:after,&:before{
            display:none;
        }
    }
}

/* Toggle Refinement */
.ais-ToggleRefinement-label {
	color:$dark;
	font-size: 0.875rem;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding-bottom: 0.5rem;
	font-weight:500;
}



/* Counters Result */
.ais-RefinementList-count, .ais-Menu-count{
    color: $base;
    font-weight:500;
    background: rgba($black,.4);
    border-radius: 3px;
	font-size: .8rem;
	
}

.ais-HierarchicalMenu-count{
	color: $base;
    font-weight:500;
    background: rgba($black,.4);
    border-radius: 3px;
	font-size: .8rem;
	margin-left: 0.4em;
}


/* Buttons */
.ais-ClearRefinements-button, .ais-CurrentRefinements-reset, .ais-GeoSearch-redo, .ais-GeoSearch-reset, .ais-HierarchicalMenu-showMore, .ais-InfiniteHits-loadMore, .ais-InfiniteResults-loadMore, .ais-Menu-showMore, .ais-RefinementList-showMore{
    background-color: $active;
    opacity: 1;
    position: relative;
    &:hover, &:focus{
        background-color: $primary;
    }
}

/* Custom */
.ais-RefinementList-checkbox,.ais-NumericMenu-radio{
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        width: 35px;
        height: 35px;
		background: rgba($primary, 0.8);
        opacity: 0; 
        border-radius: 100%; 
        transform: scale(0) translate(-50);
        transform-origin: 50% 50%;
    }
    &:focus:not(:active)::after {
        animation: ripple 0.6s ease-out;
	}
}

.ais-RefinementList-checkbox:checked::after{
	background-color: $primary !important;
}


.ais-ToggleRefinement-label {
	cursor: pointer;
	display: flex;
}
  
.ais-ToggleRefinement-checkbox {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background:rgba($black,.4);
	border: none;
	border-radius: 8px;
	cursor: pointer;
	height: 16px;
	margin-right: 0px;
	min-width: 30px;
	transition: background 150ms ease-out;
}

.ais-ToggleRefinement-checkbox:checked {
	background:rgba($primary,0.8);
}

.ais-ToggleRefinement-checkbox::after {
	background-image: linear-gradient(to top, #f5f5fa, #fff);
	border-radius: 100%;
	box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
		0 2px 3px 0 rgba(93, 100, 148, 0.2);
	content: '';
	height: 16px;
	position: absolute;
	transition: transform 150ms ease-out;
	width: 16px;
}

.ais-ToggleRefinement-checkbox:checked::after {
	transform: translateX(100%);
}

@keyframes ripple {
    0% {
      transform: scale(0, 0);
      opacity: 1;
    }
    20% {
      transform: scale(0.5, 0.5);
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1, 1);
    }
}

@media (min-width: 1550px){
    .iron-shop-wrapper .ais-Hits .ais-Hits-list .ais-Hits-item {
        flex: 0 0 33%;
        max-width: 33%;
    }
}

@media (min-width: 960px){
    .form-margin {
        margin-left: -40px;
    }    
}

