/*------------------------------------------------------------
    3.4 button
-----------------------------------------------------------*/

button:focus{
  outline:none;
}
// base button style
.button{
  padding:0.5rem 1rem !important;
  border:none !important;
  //border-radius: 2px !important;
  background-color:$btn-bg !important;
  box-shadow: 0 3px 1px -2px rgba($black,.2),
   0 2px 2px 0 rgba($black,.14),
    0 1px 5px 0 rgba($black,.12);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  span{
    color:$black;
    font-family:inherit;
    font-size: 0.875rem;
    font-weight:$font-weight-medium;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}
.btn-primary{
   background-color: $primary !important; 
   span{
      color:$base;
   } 
}
//active button style
.btn-active{
  color:$base;
  background-color: $active !important;
  span{
    color:$base;
  }
}
.btn-danger{
   background-color: $btn-danger !important; 
   span{
      color:$base;
   }
}
.btn-outlined{
  border: 2px solid $base !important;
  background-color: $transparent !important;
  border-radius: 8px !important;
  span{
    color:$base;
  }
}
.btn-inactive{
	pointer-events: none;
	color:$base;
	background-color: $inactive !important;
	span{
	  color:$inactive2;
	}
}
.btn-sm{
   padding:0.36rem 1rem !important; 
}
//button with white background
.btn-base{
  color:$black;
  background-color: $base !important;
  span{
    color:$black;
    font-weight:$font-weight-medium;
  }
}
//large button size
.btn-lg{
  min-width: 10rem !important;
  border-radius: 2px !important;
  min-height: 3.125rem !important;
  span{
    &:first-child{
      line-height: 2.3;
    }
  }
}
.btn-lgst{
  min-width: 20rem !important;
  border-radius: 2px !important;
  min-height: 4rem !important;
  span{
    &:first-child{
      line-height: 2.3;
    }
    font-size: 18px;
    font-weight: 600;
  }
}
.btn-lg-pr-add{
  min-width: 9rem !important;
}
//cart popup button style
.cart-button{
  font-size: 0.75rem;
  letter-spacing:0;
}
// badge style
.badge-active{
    span{
      min-width: 1.375rem;
      min-height:1.375rem;
      width: 1.375rem;
      height:1.375rem;
      font-size: 0.75rem;
      font-weight:700;
      color:$base;
      background-color:$active;
      line-height: 1;
      top: -8px;
      right: -8px;
    }
}
.transparent-btn{
  border: 2px solid $btn-transparent-bdr !important;
  background-color:rgba($black,0) !important;
  display: block !important;
  padding: .8rem !important;
  margin-bottom: 2.5rem !important;
  width:100%;
  box-shadow:none !important;
  cursor: pointer;
  span{
    text-align: center;
    font-weight: 700;
    color: $secondary !important;
    text-transform: uppercase;
    line-height: 1.5rem;
    font-size:0.875rem;
  }
}
.wishlist-active{
  span i{
    color:$dark !important;
  }
}